import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { DynamicControl } from "../../../../../dynamic-forms/dynamic-control.model";
import { FixedArray } from "../../../../../dynamic-forms/form-arrays/fixed-array/fixed-array.model";
import { SaveArray } from "../../../../../dynamic-forms/form-arrays/save-array/save-array.model";
import { AbaHeightGroup } from "../../../../../dynamic-forms/form-groups/aba-height-group/aba-height-group.model";
import { BmiGroup } from "../../../../../dynamic-forms/form-groups/bmi-group/bmi-group.model";
import { ConditionalGroup } from "../../../../../dynamic-forms/form-groups/conditional-group/conditional-group.model";
import { ConditionalNrcGroup } from "../../../../../dynamic-forms/form-groups/conditional-nrc-group/conditional-nrc-group.model";
import { DischargeGroup } from "../../../../../dynamic-forms/form-groups/discharge-group/discharge-group.model";
import { DynamicGroup } from "../../../../../dynamic-forms/form-groups/dynamic-group.model";
import { NegativeReasonCodeGroup } from "../../../../../dynamic-forms/form-groups/negative-reason-code-group/negative-reason-code-group.model";
import { DeliveryGroup } from "../../../../../dynamic-forms/form-groups/ppc/delivery-group/delivery-group.model";
import { DodDeliveryGroup } from "../../../../../dynamic-forms/form-groups/ppc/dod-delivery-group/dod-delivery-group.model";
import { EarlyLateIndicatorGroup } from "../../../../../dynamic-forms/form-groups/ppc/early-late-indicator-group/early-late-indicator-group.model";
import { EddPrenatalGroup } from "../../../../../dynamic-forms/form-groups/ppc/edd-prenatal-group/edd-prenatal-group.model";
import { PrenatalGroup } from "../../../../../dynamic-forms/form-groups/ppc/prenatal-group/prenatal-group.model";
import { SaveGroup } from "../../../../../dynamic-forms/form-groups/save-group/save-group.model";
import { StandardGroup } from "../../../../../dynamic-forms/form-groups/standard-group/standard-group.model";
import { Autocomplete } from "../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Checkbox } from "../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { ControlTypes } from "../../../../../dynamic-forms/inputs/control-types.enum";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxSource } from "../../../../../dynamic-forms/inputs/textbox-source/textbox-source.model";
import { TextboxType } from "../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GenericAsyncValidator } from "../../../../../dynamic-forms/validators/generic.async-validator";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../api/member-validation/dynamic-entity-attribute.model";
import { DynamicEntity } from "../../../../api/member-validation/dynamic-entity.model";
import { EntityError } from "../../../../api/member-validation/entity-error.model";
import { Attribute } from "./attribute.model";
import { ExlcusionAndContra } from "./exclusion-and-contra/exclusion-and-contra.model";

@Injectable({
  providedIn: "root",
})
export class ChartService {
  private onChange = new Subject<any>();
  refreshCodingForm = new BehaviorSubject<boolean>(false);

  dobValidationChange = new BehaviorSubject<DynamicEntityAttribute>(null);
  dobValidationChangeObserver$: Observable<DynamicEntityAttribute> = this.dobValidationChange.asObservable();

  genderFoundValidationChange = new BehaviorSubject<DynamicEntityAttribute>(null);
  genderFoundChangeObserver$: Observable<DynamicEntityAttribute> = this.genderFoundValidationChange.asObservable();
  private isQualityEve = false;
  private addNewExclusion$ = new Subject<void>();
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private readonly http: HttpClient,
    private readonly automapper: AutomapperService,
    private genericAsyncValidator: GenericAsyncValidator
  ) { }

  addExclusion(): void {
    this.addNewExclusion$.next();
  }

  addExclusionEvent$(): Observable<void> {
    return this.addNewExclusion$.asObservable();
  }

  onChangeSubscribe(action: () => void): Subscription {
    return this.onChange.subscribe(action);
  }

  onChangeEmit = (): void => {
    this.onChange.next();
  }

  getEntities(chaseId: number): Observable<DynamicEntity[]> {
    const url = `${this.baseApiUrl}chase/get/entities?chaseId=${chaseId}`;

    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "DynamicEntity"))
    );
  }

  // TODO: incorporate getChaseGdFromPath. Currently this is not working.
  getConfiguration(chaseId: number, isQualityEve: boolean): Observable<DynamicControl[]> {
    const url = `${this.baseApiUrl}measure/form?chaseId=${chaseId}`;
    this.isQualityEve = isQualityEve;
    return this.http.get(url).pipe(
      map((config: any) => this.toDynamicControls(config, null))
    );
  }

  getOverreadFeedbackConfiguration(chaseId: number, workflowStatus: string): Observable<DynamicControl[]> {
    const url = `${this.baseApiUrl}measure/form?chaseId=${chaseId}&workflowStatus=${workflowStatus}`;

    return this.http.get(url).pipe(
      map((config: any) => this.toDynamicControls(config, null))
    );
  }

  delete(attributes: DynamicEntityAttribute[]): Observable<null> {
    const url = `${this.baseApiUrl}measure/delete`;
    return this.http.post(url, attributes) as Observable<null>;
  }

  deleteEncounter(attributes: DynamicEntityAttribute[], processChildEntity: number): Observable<null> {
    const url = `${this.baseApiUrl}measure/delete/encounter?processChildEntity=${processChildEntity}`;
    return this.http.post(url, attributes) as Observable<null>;
  }

  save(attributes: DynamicEntityAttribute[]): Observable<DynamicEntityAttribute[]> {
    const url = `${this.baseApiUrl}measure/save`;

    return this.http.post(url, attributes).pipe(
      map(this.setEntityIds(attributes))
    );
  }

  saveDemographic(attributes: DynamicEntityAttribute[]): Observable<boolean> {
    const url = `${this.baseApiUrl}measure/demographic/save`;

    return this.http.post(url, attributes) as Observable<boolean>;
  }

  save2(attributes: DynamicEntityAttribute[]): Observable<DynamicEntity[]> {
    const url = `${this.baseApiUrl}measure/save2`;

    return this.http.post(url, attributes).pipe(
      map(this.automapper.curryMany("default", "DynamicEntity"))
    );
  }

  getCompliance(chaseId: number): Observable<any> {
    const url = `${this.baseApiUrl}measurecompliance/get?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map((data: any[]) => {
        data.forEach(item => {
          item.chaseCompliance.negativeReasonCodeName = this.getLabel(item.chaseCompliance.negativeReasonCodeName, item.measureYear);
        });
        return data;
      })
    );
  }

  getStartingCompliance(chaseId: number): Observable<any> {
    const url = `${this.baseApiUrl}measurecompliance/samplecompliance?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map((data: any[]) => {
        data.forEach(item => {
          item.chaseCompliance.negativeReasonCodeName = this.getLabel(item.chaseCompliance.negativeReasonCodeName, item.measureYear);
        });
        return data;
      })
    );
  }

  submit(chaseId: number, status: string): Observable<any> {
    const url = `${this.baseApiUrl}measure/submit?chaseId=${chaseId}&status=${status}`;
    return this.http.post(url, null);
  }

  submit2(chaseId: number, status: string): Observable<EntityError[]> {
    const url = `${this.baseApiUrl}measure/submit2?chaseId=${chaseId}&status=${status}`;
    return this.http.post(url, null).pipe(
      map(this.automapper.curryMany("default", "EntityError"))
    );
  }

  getExclusionAndContra(chaseId: number,
                        measureCode: string,
                        measureYear: number,
                        workflowStatus?: string): Observable<ExlcusionAndContra> {
    const url = `${this.baseApiUrl}exclusionandcontra/get?chaseId=${chaseId}&measureCode=${measureCode}&measureYear=${measureYear}&workflowStatus=${workflowStatus}`;
    return this.http.get(url).pipe(
      map(this.automapper.curry("default", "ExlcusionAndContra")),
      map((data: ExlcusionAndContra) => {
        data.exclusionOptions.forEach((option, i) => {
          const newoption = new SelectableInput({
            text: this.getLabel(option.text, measureYear),
            value: option.value,
          });
          data.exclusionOptions[i] = newoption;
        });
        data.exclusionList.forEach(exclusion => {
          exclusion.reasonOptions = [...data.exclusionOptions];
          exclusion.reasonInfo.options = [...data.exclusionOptions];
        });
        data.contra.reasonInfo.options.forEach(option => option.text = this.getLabel(option.text, option.extra.MeasureYear));

        if (data.hasLessThanSeven) {
          data.lessThanSeven.reasonInfo.options.forEach(option => option.text = this.getLabel(option.text, option.extra.MeasureYear));
        }

        return data;
      })
    );
  }

  getComplianceCodes(isAuditGrid?: boolean): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}measurecompliance/codes?isAuditGrid=${isAuditGrid}`;
    return this.http.get(url)
      .pipe(map(this.automapper.curryMany("string", "SelectableInput")));
  }

  getAdditionalMemberDetail(chaseId: number): Observable<DynamicEntityAttribute> {
    const url = `${this.baseApiUrl}membervalidationdetail/get?chaseId=${chaseId}`;
    return this.http.get(url)
      .pipe(map(this.automapper.curry("default", "DynamicEntityAttribute"))
      );
  }

  getMemberValidationAttributeByCode(chaseId: number, attributeCode: string): Observable<DynamicEntityAttribute> {
    const url = `${this.baseApiUrl}membervalidation/get/attributeByCode?chaseId=${chaseId}&attributeCode=${attributeCode}`;
    return this.http.get(url) as Observable<DynamicEntityAttribute>;
  }

  getErrors(chaseId: number): Observable<EntityError[]> {
    const url = `${this.baseApiUrl}measure/errors?chaseId=${chaseId}`;
    return this.http.get(url).pipe(
      map(this.automapper.curryMany("default", "EntityError"))
    );
  }

  updateRefreshCodingForm(isRefreshCodingForm: boolean): void {
    this.refreshCodingForm.next(isRefreshCodingForm);
  }

  private toDynamicControls = (configuration: any[], parent: DynamicControl = null): DynamicControl[] => {
    return configuration.reduce(this.toControls(parent), []);
  }

  private toControls = (parent: DynamicControl) => {
    return (groups: any[], item: any): DynamicControl[] => {
      if (!(item.attributeIsAdmin || item.attributeIsSupplemental) || item.attributeCode !== "ChartPageNumber") {
        const model = this.getControl(item, parent);
        groups.push(model);
      }

      return groups;
    };
  }

  private getControl = (configuration: any, parent: DynamicControl, index: number = null): DynamicControl => {
    const controlType = configuration.objectType;
    const label = this.getLabel(configuration.displayLabel, configuration.measureYear);
    const value = configuration.attributeValue;
    const key = this.getKey(configuration, index);
    const saveInfo = this.getSaveInfo(configuration);
    const isAdmin = configuration.attributeIsAdmin;
    const isChanged = configuration.attributeIsChanged;
    const workflowStatusName = configuration.workflowStatusName;
    const isNlp = configuration.attributeIsNlp;
    const isSupplemental = configuration.attributeIsSupplemental;
    const isDva = configuration.attributeIsDva;

    let control;

    const controlMap: Map<string, Function> = new Map([
      ["standard-group", () => {
        control = new StandardGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
          isNlp,
          isSupplemental,
          isDva,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["conditional-group", () => {
        control = new ConditionalGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["bmi-group", () => {
        control = new BmiGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["aba-height-group", () => {
        control = new AbaHeightGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["save-group", () => {
        control = new SaveGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["negative-reason-code-group", () => {
        control = new NegativeReasonCodeGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["conditional-nrc-group", () => {
        control = new ConditionalNrcGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["save-array", () => {
        const template = this.deepCopyAndClean(configuration.children[0]);
        control = new SaveArray({
          parent,
          key,
          header: label,
          template: () => this.getControl(template, parent) as DynamicGroup,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.models = configuration.children.map((child, childIndex) => this.getControl(child, control, childIndex));
      }],
      ["fixed-array", () => {
        const templateFixed = this.deepCopyAndClean(configuration.children[0]);
        control = new FixedArray({
          parent,
          key,
          header: label,
          template: () => this.getControl(templateFixed, parent) as DynamicGroup,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.models = configuration.children.map((child, childIndex) => this.getControl(child, control, childIndex));
      }],
      ["early-late-indicator-group", () => {
        control = new EarlyLateIndicatorGroup({ parent });
      }],
      ["prenatal-group", () => {
        control = new PrenatalGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["delivery-group", () => {
        control = new DeliveryGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["dod-delivery-group", () => {
        control = new DodDeliveryGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["edd-prenatal-group", () => {
        control = new EddPrenatalGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["discharge-group", () => {
        control = new DischargeGroup({
          parent,
          key,
          header: label,
          isAdmin,
          isChanged,
          workflowStatusName,
        });
        control.controls = this.toDynamicControls(configuration.children, control);
      }],
      ["textbox", () => {
        control = new Textbox({
          parent,
          key,
          type: this.getTextboxType(configuration.attributeDataType),
          dataType: configuration.attributeDataType,
          label,
          value,
          saveInfo,
          asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
          isAdmin,
          isChanged,
          workflowStatusName,
          isNlp,
          isSupplemental,
          isDva,
          readonly: isSupplemental ? isSupplemental : this.isAdminGroup(parent),
          pageNumber: configuration.pageNumber,
        });
      }],
      ["textboxsource", () => {
        control = new TextboxSource({
          parent,
          key,
          type: this.getTextboxType(configuration.attributeDataType),
          dataType: configuration.attributeDataType,
          label,
          value,
          saveInfo,
          asyncValidators: [this.genericAsyncValidator.validate.bind(this.genericAsyncValidator)],
          isAdmin,
          isChanged,
          workflowStatusName,
          isNlp,
          isSupplemental,
          isDva,
        });
      }],
      ["autocomplete", () => {
        const options = this.automapper.mapMany("default", "SelectableInput", JSON.parse(configuration.attributeItemList)) as SelectableInput[];
        options.forEach(option => option.text = this.getLabel(option.text, configuration.measureYear));
        control = new Autocomplete({
          parent,
          key,
          label,
          placeholder: "Select...",
          options,
          value,
          saveInfo,
          isAdmin,
          isChanged,
          workflowStatusName,
          isNlp,
          isSupplemental,
          isDva,
          disabled: isNlp,
        });
      }],
      ["checkbox", () => {
        control = new Checkbox({
          parent,
          key,
          label,
          isAdmin,
          isChanged,
          workflowStatusName,
          isNlp,
          isSupplemental,
          isDva,
        });
      }],
    ]);

    if (controlMap.has(controlType)) {
      controlMap.get(controlType)();
    } else {
      throw new Error(`Control Type '${controlType}' does not exist.`);
    }

    return control;
  }

  getLabel(label: string, measureYear: number): string {
    if (!StringHelper.isAvailable(label)) {
      return "";
    }

    let result = label;
    for (let i = 0; i < 15; ++i) {
      const year = measureYear - i;
      const searchTerm = i === 0 ? "{MeasureYear}" : `{MeasureYear-${i}years}`;
      result = StringHelper.replaceAll(result, searchTerm, year.toString());
    }
    return result;
  }

  private deepCopyAndClean(template: any): any {
    const group = { ...template };
    group.attributeIsAdmin = false;
    group.attributeIsNlp = false;
    group.attributeIsSupplemental = false;
    group.attributeIsDva = false;
    group.children = group.children.map(child => ({ ...child }));
    group.children.forEach((child: any) => {
      child.attributeValue = null;
      child.entityId = null;
      child.attributeIsAdmin = false;
      child.attributeIsNlp = false;
      child.attributeIsSupplemental = false;
      child.attributeIsDva = false;

      if (child.children && child.children.length > 0) {
        this.deepCopyAndClean(child);
      }
    });
    return group;
  }

  private getTextboxType(type: string): TextboxType {
    if (!StringHelper.isAvailable(type)) {
      return null;
    }

    switch (type.toLowerCase()) {
      case "date":
        return TextboxType.TEXT;
      case "numeric":
        return TextboxType.NUMBER;
      default:
        throw new Error(`The type '${type}' does not exist.`);
    }
  }

  getKey(configuration: any, index: number): string {
    if (index != null) {
      return index.toString();
    }

    const entityId = configuration.entityId;
    const attributeId = configuration.attributeID;
    const labelKey = configuration.objectType !== "conditional-nrc-group" ?
      StringHelper.removeNonAlphaNumericCharacters(configuration.displayLabel) :
      StringHelper.removeNonAlphaNumericCharacters(configuration.children[0].displayLabel);
    const configKey = entityId && attributeId ? `${entityId}_${attributeId}` : labelKey;

    const key = StringHelper.isAvailable(configKey) ? configKey : this.createRandomId();
    return key;
  }

  private getSaveInfo(configuration: any): Attribute {
    const saveInfo = new Attribute({
      chaseId: configuration.chaseId,
      entityId: configuration.entityId,
      entityTypeId: configuration.entityTypeId,
      sourceTypeId: null,
      dataType: configuration.attributeDataType,
      id: configuration.attributeID,
      code: configuration.attributeCode,
    });
    return saveInfo;
  }

  private setEntityIds(attributes: DynamicEntityAttribute[]): (data: any[]) => DynamicEntityAttribute[] {
    return (data: any[]) => {
      attributes.forEach(attribute => {
        const newInfo = data.find(item => item.id === attribute.id);
        if (newInfo != null) {
          attribute.entityId = newInfo.entityId;
          attribute.isChanged = newInfo.isChanged;
        }
      });
      return attributes;
    };
  }

  private createRandomId(): string {
    // https://stackoverflow.com/a/8084248/2573621
    return Math.random().toString(36).substring(7);
  }

  saveCodingScreenshot(file: FormData, chaseId: number, auditPackageItemId: number): Observable<null> {
    const url = `${this.baseApiUrl}chase/savecodingscreenshot?chaseId=${chaseId}&auditPackageItemId=${auditPackageItemId}`;

    return this.http.post(url, file) as Observable<null>;
  }

  getDataEntryPages(chaseId: number): Observable<number[]> {
    const url = `${this.baseApiUrl}measure/dataentrypages?chaseId=${chaseId}`;
    return this.http.post(url, {}) as Observable<number[]>;
  }

  getTotalChaseDocumentPages(chaseId: number): Observable<number> {
    const url = `${this.baseApiUrl}chase/totalchasepages?chaseId=${chaseId}`;
    return this.http.get(url) as Observable<number>;
  }

  isNLPSource(controls: any[]): boolean {
    return controls.some(control => control.isNlp);
  }

  isDVASource(controls: any[]): boolean {
    return controls.some(control => control.isDva);
  }

  private isAdminGroup(control: any): boolean {
    return control.header.includes("(Admin)");
  }
}
