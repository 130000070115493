import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { finalize } from "rxjs/operators";
import { SubSink } from "subsink";
import { AuthService } from "../../../../../../../auth/auth.service";
import { UserToken } from "../../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { LocalService } from "../../../../../../../core/storage/local.service";
import { UserService } from "../../../../../../../core/user/user.service";
import { DynamicControl } from "../../../../../../../dynamic-forms/dynamic-control.model";
import { DynamicFormEvent } from "../../../../../../../dynamic-forms/dynamic-form-event.model";
import { DynamicArray } from "../../../../../../../dynamic-forms/form-arrays/dynamic-array.model";
import { DynamicGroup } from "../../../../../../../dynamic-forms/form-groups/dynamic-group.model";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { DynamicInput } from "../../../../../../../dynamic-forms/inputs/dynamic-input.model";
import { DocumentViewerSessionService } from "../../../../../../../shared/document/document-page-viewer/document-viewer-session.service";
import { ListItem } from "../../../../../../../shared/list/list-item";
import { MenuService } from "../../../../../../../shared/menu/menu.service";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../../api/member-validation/dynamic-entity-attribute.model";
import { EntityType } from "../../../../../../api/member-validation/entity-type.enum";
import { MemberValidation } from "../../../../../../api/member-validation/member-validation.model";
import { MemberValidationService } from "../../../../../../api/member-validation/member-validation.service";
import { WorkflowStatusDb } from "../../../../../../api/workflow/workflow-status-db.enum";
import { FunctionalRole } from "../../../../../dashboard/retrieval/functional-role.enum";
import { ProjectType } from "../../../../../project/project-type.enum";
import { DirectoryUserRole } from "../../../../../retrieval/directory-user-role";
import { DATE_OF_BIRTH_VALIDATION_RESULT, GENDER_FOUND, MEMBER_VALIDATION_RESULT } from "../../../../chase-detail/chase-detail-chart/attributes";
import { ChartService } from "../../../../chase-detail/chase-detail-chart/chart.service";
import { DobReasonType } from "../../../../chase-detail/chase-detail-chart/member-dob-gen/dob-reason-type.enum";
import { RiskEntity } from "../../../../chase-detail/chase-detail-chart/risk/risk-entity.model";
import { RiskService } from "../../../../chase-detail/chase-detail-chart/risk/risk.service";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { ChaseDetailStateService } from "../../../../chase-detail/chase-detail-state.service";
import { ChaseDetailService } from "../../../../chase-detail/chase-detail.service";
import { ChaseQueueService } from "../shared/chase-queue.service";

@Component({
  selector: "member-risk-member",
  templateUrl: "./risk-member.component.html",
  styleUrls: ["./risk-member.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMemberComponent implements OnInit {
  private sink = new SubSink();
  private dobAttribute: DynamicEntityAttribute;
  private genderAttribute: DynamicEntityAttribute;
  auditMode = false;
  chaseDetails: ListItem[] = [];
  chaseDetailState: ChaseDetailState;
  chaseId: number;
  deleteEvent: DynamicFormEvent;
  formGroup = new FormGroup({});
  formModels: DynamicControl[];
  memberIsValid = false;
  memberValidationData = new MemberValidation();
  showConfirmation = false;
  showMemberInputs = true;
  showSuccessToast = true;
  isMemberValidate = true;
  memberValidationDataFetched = false;
  openChaseAndUserInfo = "openChaseAndUserInfo";
  globalLocalStoreChaseId = null;
  user: UserToken;

  constructor(
    private documentViewerSessionService: DocumentViewerSessionService,
    private readonly authService: AuthService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly chartService: ChartService,
    private readonly chaseDetailService: ChaseDetailService,
    private readonly chaseDetailStateService: ChaseDetailStateService,
    private readonly formService: FormService,
    private readonly memberValidationService: MemberValidationService,
    private readonly messagingService: MessagingService,
    private readonly riskService: RiskService,
    private readonly route: ActivatedRoute,
    public menuService: MenuService,
    private localService: LocalService,
    private readonly chaseQueueService: ChaseQueueService,
    private readonly userService: UserService
  ) { }

  get status(): string {
    const statusItem = this.chaseDetails.find(item => item.key === "Workflow Status");
    return statusItem ? statusItem.value : "";
  }

  get isMemberCentricChase(): boolean {
    return this.chaseDetailState.isMemberCentric;
  }

  get isEnabled(): boolean {
    // If Chart page displayed from Audit Module, make the chart page READ-ONLY
    // TODO: Fix logic to remove the ternary.
    return this.auditMode ? false : (this.isMemberValidOrIsAdrv && this.assignedToCurrentUser);
  }

  get isMemberValidOrIsAdrv(): boolean {
    return this.memberIsValid || this.isAdrv;
  }

  get assignedToCurrentUser(): boolean {
    if (this.assignedToId == null) {
      return true;
    }

    return this.assignedToId === this.authService.userId;
  }

  get isMemberValidationEnabled(): boolean {
    return StringHelper.isAvailable(this.status) && this.assignedToCurrentUser && !this.chaseStatus
    && !this.isRoleAbstractorOrOverreader();
  }

  private get chaseStatus() {
    return this.chaseDetailState?.projectConfiguration?.codingReviewMode === "Diagnosis" &&
    (this.chaseDetailState?.workflowStatus === WorkflowStatusDb.Completed || this.chaseDetailState?.workflowStatus === WorkflowStatusDb.Delivery);
  }

  get isMemberDOBFormEnabled() {
    return  this.isEnabled && !this.chaseStatus;
  }
  get assignedToId(): number {
    const assignedToIdItem = this.chaseDetails.find(item => item.key === "Assigned To Id");
    if (assignedToIdItem == null) {
      return null;
    }

    return +assignedToIdItem.value;
  }

  get measureCode(): string {
    return this.chaseDetailState?.measureCode;
  }

  get projectType(): ProjectType {
    const projectTypeIdItem = this.chaseDetails.find(item => item.key === "Project Type Id");
    return (projectTypeIdItem ? Number(projectTypeIdItem) : null) as ProjectType;
  }

  get useRisk(): boolean {
    return this.isHcc || this.isHst || this.isMcd;
  }

  get isHcc(): boolean {
    return this.measureCode === "HCC";
  }

  get isHst(): boolean {
    return this.measureCode === "HST";
  }

  get isMcd(): boolean {
    return this.measureCode === "MCD";
  }

  get isAdrv(): boolean {
    return this.measureCode === "ADRV";
  }

  get isCodingReviewModeDX(): boolean {
    return this.chaseDetailState?.projectConfiguration?.codingReviewMode === "Diagnosis";
  }

  get functionalRoleId(): number {
    switch (this.chaseDetailState.workflowStatus) {
      case WorkflowStatusDb.Abstraction:
        return FunctionalRole.ABSTRACTOR;
      case WorkflowStatusDb.Overread:
        return FunctionalRole.OVERREAD;
      case WorkflowStatusDb.Overread2:
        return FunctionalRole.CLIENTOVERREAD;
      default:
        return 0;
    }
  }

  ngOnInit(): void {
    this.user = this.userService.getUserToken();
    this.sink.add(
      this.route.parent.paramMap.subscribe(params => {
        this.chaseId = NumberHelper.isGreaterThan(+params.get("chaseGd"), 0) ? +params.get("chaseGd") : 0;
      }),
      this.chaseDetailService.chaseDetailsChangeObserver.subscribe(chaseDetails => {
        this.chaseDetails = chaseDetails;
        if (!this.memberValidationDataFetched && ArrayHelper.isAvailable(this.chaseDetails)) { this.getMemberValidation(); }
      }),
      this.chaseDetailStateService.state.subscribe(state => {
        this.chaseDetailState = state;
        this.riskService.setData({ assignedToCurrentUser: this.assignedToCurrentUser });
        this.changeDetector.markForCheck();
      }),
      this.riskService.data
      .subscribe(riskState => {
        if (!riskState.assignedToCurrentUser) {
          this.memberIsValid = false;
          this.changeDetector.markForCheck();
        }

      })

    );
  }


  memberValidationSubmit(memberValidation: MemberValidation, showToast: boolean = false): void {
    const isValid = memberValidation.isValid;
    this.memberValidationService.submit(memberValidation, this.status).subscribe(data => {
      this.memberValidationData = data;
      if (isValid) {
        this.autoSaveMemberDobGen();
      }
      if (showToast) {
        this.messagingService.showToast("Member Validation Succeeded", SeverityType.SUCCESS);
      }
      this.memberIsValid = this.memberValidationData.isValid;
      this.markAllAsDisabledIfMemberIsNotValid(this.formModels);
      this.chartService.onChangeEmit();

      // TODO: How to handle HST/ENR/ATT?
      if (this.useRisk && isValid) {
        // FIXME: There's probably a better way than hard coding this.
        this.setMemberValidationResult();
        this.documentViewerSessionService.updateIsMemberValidated(this.memberIsValid);
      } else if (!isValid) {
        this.chaseQueueService.getQueueOrNextChase(this.user.userId, this.functionalRoleId, this.chaseDetailState);
      }
    });
  }

  showMemberValidationInputs(isCorrectMember: boolean): void {
    this.showMemberInputs = isCorrectMember;
    this.isMemberValidate = isCorrectMember;
  }

  handleChange(event: DynamicFormEvent): void {
    switch (event.type) {
      case "compliance":
        this.chartService.onChangeEmit();
        break;
      case "save":
        this.save(event);
        break;
      case "clear":
      case "delete":
        this.confirmDelete(event);
        break;
      default:
        break;
    }
  }

  autoSaveMemberDobGen(): void {
    if (!this.dobAttribute.value) {
      this.dobAttribute.value = DobReasonType.YES;
    }
    if (!this.genderAttribute.value) {
      this.genderAttribute.value = DobReasonType.YES;
    }

    this.chartService
      .save([this.dobAttribute, this.genderAttribute])
      .subscribe();
  }

  save(event: DynamicFormEvent): void {
    const isNLPSource = this.chartService.isNLPSource(event.model.controls);
    if (isNLPSource) {
      return;
    }

    const attributes = event.model.controls
      .filter(model => model.controlType !== "textbox-source")
      .map(model => {
        model.saveInfo.value = this.getValue(model.key, event.value);
        return model.saveInfo;
      });

    const hasAnyValue = attributes.some(attribute => StringHelper.isAvailable(attribute.value));
    if (hasAnyValue) {
      this.chartService
        .save(attributes)
        .pipe(finalize(() => {
          this.chartService.onChangeEmit();
          this.formService.updateDom.next();
        }))
        .subscribe(
          newAttributes => {
            event.model.controls.forEach(control => {
              const attribute = newAttributes.find(newAttribute => newAttribute.id === control.saveInfo?.id);
              control.saveInfo = attribute;
              control.isChanged = attribute?.isChanged;
            });
            event.successFn();
            this.updateMemberDobGenAttributeValues(newAttributes);
          },
          (e: HttpErrorResponse) => {
            event.control.setErrors({ saveError: e.error });
          }
        );
    } else {
      this.delete(event);
    }
  }

  confirmDelete(event: DynamicFormEvent): void {
    const isNLPSource = this.chartService.isNLPSource(event.model.controls);
    if (isNLPSource) {
      this.showConfirmation = true;
      this.deleteEvent = event;
    } else {
      this.delete(event);
    }
  }

  delete(event: DynamicFormEvent): void {
    const controls = event.model.controls as DynamicInput[];
    const attributes = controls.map(control => control.saveInfo);
    const hasEntityId = attributes.some(attribute => attribute.entityId != null);

    if (hasEntityId) {
      this.chartService
        .delete(attributes)
        .subscribe(() => {
          event.successFn();
          this.chartService.onChangeEmit();
        });
    } else {
      event.successFn();
    }
  }

  private updateMemberDobGenAttributeValues(newAttributes: DynamicEntityAttribute[]): void {
    const dobAttribute = newAttributes.find(attribute => attribute.attributeCode === DATE_OF_BIRTH_VALIDATION_RESULT.attributeCode);
    const genderAttribute = newAttributes.find(attribute => attribute.attributeCode === GENDER_FOUND.attributeCode);

    if (dobAttribute) {
      this.dobAttribute = dobAttribute;
    }

    if (genderAttribute) {
      this.genderAttribute = genderAttribute;
    }
  }

  private getMemberValidation(): void {
    this.memberValidationDataFetched = true;
    if (this.chaseId && StringHelper.isAvailable(this.status)) {
      this.memberValidationService.getData(this.chaseId, this.status).subscribe({
        next: data => {
          this.memberValidationData = data;

          this.dobAttribute = this.memberValidationData.dateOfBirthValidationResult;
          delete this.dobAttribute.options;
          this.chartService.dobValidationChange.next(this.memberValidationData.dateOfBirthValidationResult);

          this.genderAttribute = this.memberValidationData.genderFoundValidationResult;
          this.chartService.genderFoundValidationChange.next(this.memberValidationData.genderFoundValidationResult);

          if (this.isMemberCentricChase && ArrayHelper.isAvailable(this.memberValidationData.reasonInfo.options)) {
            const index = this.memberValidationData.reasonInfo.options.findIndex(x => x.value === "5");
            this.memberValidationData.reasonInfo.options.splice(index, 1);
          }

          this.memberIsValid = this.memberValidationData.isValid;
          this.markAllAsDisabledIfMemberIsNotValid(this.formModels);

          if (this.isCodingReviewModeDX) {
            this.setMemberValidationResult();
          }

          this.changeDetector.markForCheck();
        },
        error: () => {
          this.memberIsValid = false;
          this.markAllAsDisabledIfMemberIsNotValid(this.formModels);
          this.messagingService.showToast("Correct Member is not available.", SeverityType.ERROR);
        },
      });
    }
  }

  private markAllAsDisabledIfMemberIsNotValid = (models: DynamicControl[]): void => {
    if (!ArrayHelper.isAvailable(models)) {
      setTimeout(() => this.markAllAsDisabledIfMemberIsNotValid(this.formModels), 100);
    }

    (models || []).forEach(model => {
      const controls = (model as DynamicGroup).controls || (model as DynamicArray).models;
      model.disabled = !this.isEnabled || model.isAdmin || model.isSupplemental;

      if (ArrayHelper.isAvailable(controls)) {
        this.markAllAsDisabledIfMemberIsNotValid(controls);
      } else if (typeof (model as DynamicInput).getMasterKey === "function") {
        const masterKey = (model as DynamicInput).getMasterKey();
        const control = this.formGroup.get(masterKey);
        if (model.disabled) {
          control.disable({ onlySelf: true });
        } else {// TODO: Look into creating method to update control properties when there are more control types.
          model.controlType !== "textbox-source" ? control?.enable() : control.disable({ onlySelf: true });
        }
      }
    });
  }

  private setMemberValidationResult(): void {
    this.riskService.setData({
      memberValidation: new RiskEntity({
        entityTypeId: EntityType.MEMBER_VALIDATION,
        attributes: [new DynamicEntityAttribute({
          ...MEMBER_VALIDATION_RESULT,
          value: this.memberValidationData.isValidInfo.value,
        })],
      }),
    });
  }

  private getValue(key: string, values: object): string {
    const value = values[key];

    if (typeof value === "object" && value != null) {
      return this.getValue("value", value);
    }

    return value;
  }

  private isRoleAbstractorOrOverreader(): boolean {
    const arr = this.authService.user.directoryRoleIds;

    if (arr.length >= 1 && (arr.includes(DirectoryUserRole.Abstractor) || arr.includes(DirectoryUserRole.Overreader))) {
      const localChaseUserInfo =  this.localService.get(this.openChaseAndUserInfo, null);
      if (localChaseUserInfo) {
        const parsedLocalChaseUserInfo = JSON.parse(localChaseUserInfo);
        this.globalLocalStoreChaseId = parsedLocalChaseUserInfo.openChaseId;
        return this.chaseId !== this.globalLocalStoreChaseId;
      }
    }
    return false;

  }

}
