export class DocumentPageDocumentHighlights {
    chaseDocumentId: string;
    documentPageIds: string[];
    checkChaseInDynamoDb: boolean;

    constructor(options: Partial<DocumentPageDocumentHighlights> = {}) {
        this.chaseDocumentId = options.chaseDocumentId;
        this.documentPageIds = options.documentPageIds;
        this.checkChaseInDynamoDb = options.checkChaseInDynamoDb || false;
    }
}
