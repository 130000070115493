import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../auth/auth-guard.guard";
import { AnalyticsHedisComponent } from "./analytics-hedis/analytics-hedis.component";
import { AnalyticsIvaComponent } from "./analytics-iva/analytics-iva.component";
import { MemberOversightDashboardComponent } from "./analytics-member-management/member-oversight-dashboard/member-oversight-dashboard.component";
import { MembershipHistoryReportComponent } from "./analytics-member-management/membership-history-report/membership-history-report.component";
import { AnalyticsReportLandingComponent } from "./analytics-report-landing/analytics-report-landing.component";
import { AidTagHistoryTrackingComponent } from "./analytics-risk/aid-tag-history-tracking/aid-tag-history-tracking.component";
import { AnalyticsRiskOverviewDwComponent } from "./analytics-risk/analytics-risk-overview-dw/analytics-risk-overview-dw.component";
import { AnalyticsRiskOverviewComponent } from "./analytics-risk/analytics-risk-overview/analytics-risk-overview.component";
import { AnnotationsReportDWComponent } from "./analytics-risk/annotations-report-dw/annotations-report-dw.component";
import { AutoCloseReportComponent } from "./analytics-risk/auto-close-report/auto-close-report.component";
import { CallCountDetailReportLegacyComponent } from "./analytics-risk/call-count-detail-report-legacy/call-count-detail-report-legacy.component";
import { CallCountDetailReportComponent } from "./analytics-risk/call-count-detail-report/call-count-detail-report.component";
import { ChartRoutingDwComponent } from "./analytics-risk/chart-routing-dw/chart-routing-dw.component";
import { ChartRoutingComponent } from "./analytics-risk/chart-routing/chart-routing.component";
import { ChaseCountsByStatusReportComponent } from "./analytics-risk/chase-counts-by-status-report/chase-counts-by-status-report.component";
import { ChaseMoveBackHistoryReportLegacyComponent } from "./analytics-risk/chase-move-back-history-report-legacy/chase-move-back-history-report-legacy.component";
import { ChaseMoveBackHistoryReportComponent } from "./analytics-risk/chase-move-back-history-report/chase-move-back-history-report.component";
import { ChaseMoveBackReportComponent } from "./analytics-risk/chase-move-back-report/chase-move-back-report.component";
import { ChaseMoveReportDwComponent } from "./analytics-risk/chase-move-report-dw/chase-move-report-dw.component";
import { ChaseMoveReportComponent } from "./analytics-risk/chase-move-report/chase-move-report.component";
import { ChaseStatusUnderlyingReportDWComponent } from "./analytics-risk/chase-status-underlying-report-dw/chase-status-underlying-report-dw.component";
import { ChaseStatusUnderlyingComponent } from "./analytics-risk/chase-status-underlying/chase-status-underlying.component";
import { ClientOverread2DWComponent } from "./analytics-risk/client-overread2-dw/client-overread2-dw.component";
import { ClientOverread2Component } from "./analytics-risk/client-overread2/client-overread2.component";
import { ClinicalAccuracyByChaseWithErrorDetailLegacyComponent } from "./analytics-risk/clinical-accuracy-by-chase-with-error-detail-legacy/clinical-accuracy-by-chase-with-error-detail-legacy.component";
import { ClinicalAccuracyByChaseWithErrorDetailComponent } from "./analytics-risk/clinical-accuracy-by-chase-with-error-detail/clinical-accuracy-by-chase-with-error-detail.component";
import { ClinicalAccuracyByMeasureComponent } from "./analytics-risk/clinical-accuracy-by-measure/clinical-accuracy-by-measure.component";
import { ClinicalProductionInventoryAndQaScoresComponent } from "./analytics-risk/clinical-production-inventory-and-qa-scores/clinical-production-inventory-and-qa-scores.component";
import { ClinicalProductivityAccuracyOverallDWComponent } from "./analytics-risk/clinical-productivity-accuracy-overall-dw/clinical-productivity-accuracy-overall-dw.component";
import { ClinicalProductivityAndAccuracyOverallComponent } from "./analytics-risk/clinical-productivity-and-accuracy-overall/clinical-productivity-and-accuracy-overall.component";
import { ClinicalProductivityOverviewComponent } from "./analytics-risk/clinical-productivity-overview/clinical-productivity-overview.component";
import { ClinicalProductivityReportDWComponent } from "./analytics-risk/clinical-productivity-report-dw/clinical-productivity-report-dw.component";
import { ClinicalProductivityComponent } from "./analytics-risk/clinical-productivity/clinical-productivity.component";
import { ClinicalSummaryComponent } from "./analytics-risk/clinical-summary/clinical-summary.component";
import { CoderProductivityDWComponent } from "./analytics-risk/coder-productivity-dw/coder-productivity-dw.component";
import { CoderProductivityComponent } from "./analytics-risk/coder-productivity/coder-productivity.component";
import { CodingDurationReportDwComponent } from "./analytics-risk/coding-duration-report-dw/coding-duration-report-dw.component";
import { CodingDurationReportComponent } from "./analytics-risk/coding-duration-report/coding-duration-report.component";
import { ConditionCategorQaReportDwComponent } from "./analytics-risk/condition-categor-qa-report-dw/condition-categor-qa-report-dw.component";
import { ConditionCategoryQaReportComponent } from "./analytics-risk/condition-category-qa-report/condition-category-qa-report";
import { ConfidenceScoreReportComponent } from "./analytics-risk/confidence-score-report/confidence-score-report.component";
import { CustomerInvoiceDetailComponent } from "./analytics-risk/customer-invoice-detail/customer-invoice-detail.component";
import { DataLoadErrorReportComponent } from "./analytics-risk/data-load-error-report/data-load-error-report.component";
import { DeletesTrackingDwComponent } from "./analytics-risk/deletes-tracking-dw/deletes-tracking-dw.component";
import { DeletesTrackingComponent } from "./analytics-risk/deletes-tracking/deletes-tracking.component";
import { DetailClinicalProductivityAndAccuracyOverallComponent } from "./analytics-risk/detail-clinical-productivity-and-accuracy-overall/detail-clinical-productivity-and-accuracy-overall.component";
import { DetailedAuditReportComponent } from "./analytics-risk/detailed-audit-report/detailed-audit-report.component";
import { DiagnosisByHccDwComponent } from "./analytics-risk/diagnosis-by-hcc-dw/diagnosis-by-hcc-dw.component";
import { DiagnosisByHccComponent } from "./analytics-risk/diagnosis-by-hcc/diagnosis-by-hcc.component";
import { DiagnosisDataMultipleModelsDWComponent } from "./analytics-risk/diagnosis-data-multiple-models-dw/diagnosis-data-multiple-models-dw.component";
import { DobDiscrepancyDWComponent } from "./analytics-risk/dob-discrepancy-dw/dob-discrepancy-dw.component";
import { DobDiscrepancyComponent } from "./analytics-risk/dob-discrepancy/dob-discrepancy.component";
import { EdpsReportDwComponent } from "./analytics-risk/edps-report-dw/edps-report-dw.component";
import { FtDailySummaryComponent } from "./analytics-risk/ft-daily-summary/ft-daily-summary.component";
import { GapStatusComponent } from "./analytics-risk/gap-status/gap-status.component";
import { HccByDosComponent } from "./analytics-risk/hcc-by-dos/hcc-by-dos.component";
import { HccSummaryByAidDwComponent } from "./analytics-risk/hcc-summary-by-aid-dw/hcc-summary-by-aid-dw.component";
import { HccSummaryByAidComponent } from "./analytics-risk/hcc-summary-by-aid/hcc-summary-by-aid.component";
import { HccSummaryByClaimIdComponent } from "./analytics-risk/hcc-summary-by-claim-id/hcc-summary-by-claim-id.component";
import { HccSummaryByMemberDwComponent } from "./analytics-risk/hcc-summary-by-member-dw/hcc-summary-by-member-dw.component";
import { HccSummaryByMemberComponent } from "./analytics-risk/hcc-summary-by-member/hcc-summary-by-member.component";
import { HccSummaryByProjectDwComponent } from "./analytics-risk/hcc-summary-by-project-dw/hcc-summary-by-project-dw.component";
import { HccSummaryByProjectComponent } from "./analytics-risk/hcc-summary-by-project/hcc-summary-by-project.component";
import { HccSummaryByProviderDwComponent } from "./analytics-risk/hcc-summary-by-provider-dw/hcc-summary-by-provider-dw.component";
import { HccSummaryByProviderComponent } from "./analytics-risk/hcc-summary-by-provider/hcc-summary-by-provider.component";
import { HccSummaryReportDwComponent } from "./analytics-risk/hcc-summary-report-dw/hcc-summary-report-dw.component";
import { HccTrackingReportComponent } from "./analytics-risk/hcc-tracking-report/hcc-tracking-report.component";
import { HistoricalStatusIndicatorsDWReportComponent } from "./analytics-risk/historical-status-indicators-dwreport/historical-status-indicators-dwreport.component";
import { HistoricalStatusIndicatorsReportComponent } from "./analytics-risk/historical-status-indicators-report/historical-status-indicators-report.component";
import { IntakeProductivityLegacyComponent } from "./analytics-risk/intake-productivity-legacy/intake-productivity-legacy.component";
import { IntakeProductivityComponent } from "./analytics-risk/intake-productivity/intake-productivity.component";
import { LifeOfAChaseComponent } from "./analytics-risk/life-of-a-chase/life-of-a-chase.component";
import { LifeOfAChaseDWComponent } from "./analytics-risk/life-of-achase-dw/life-of-achase-dw.component";
import { ManaullyCreatedChasesReportComponent } from "./analytics-risk/manually_created_chases_report/manually-created-chases-report.component";
import { MemberAdditionalDetailsComponent } from "./analytics-risk/member-additional-details/member-additional-details.component";
import { MemberChaseDwComponent } from "./analytics-risk/member-chase-dw/member-chase-dw.component";
import { MemberChaseComponent } from "./analytics-risk/member-chase/member-chase.component";
import { OpenGapsByMeasureComponent } from "./analytics-risk/open-gaps-by-measure/open-gaps-by-measure.component";
import { OpenGapsByMemberComponent } from "./analytics-risk/open-gaps-by-member/open-gaps-by-member.component";
import { PendReportDWComponent } from "./analytics-risk/pend-report-dw/pend-report-dw.component";
import { PendReportComponent } from "./analytics-risk/pend-report/pend-report.component";
import { PendsSummaryDwComponent } from "./analytics-risk/pends-summary-dw/pends-summary-dw.component";
import { PendsSummaryComponent } from "./analytics-risk/pends-summary/pends-summary.component";
import { PersistencyDWReportComponent } from "./analytics-risk/persistency-dwreport/persistency-dwreport.component";
import { PersistencyReportComponent } from "./analytics-risk/persistency-report/persistency-report.component";
import { ProjectStatusReportDwComponent } from "./analytics-risk/project-status-report-dw/project-status-report-dw/project-status-report-dw.component";
import { ProjectStatusReportComponent } from "./analytics-risk/project-status-report/project-status-report.component";
import { ProjectSummaryDwComponent } from "./analytics-risk/project-summary-dw/project-summary-dw.component";
import { ProjectSummaryComponent } from "./analytics-risk/project-summary/project-summary.component";
import { ProviderGatewayActivityComponent } from "./analytics-risk/provider-gateway-activity/provider-gateway-activity.component";
import { ProviderOutreachByContactTypeReportComponent } from "./analytics-risk/provider-outreach-by-contact-type-report/provider-outreach-by-contact-type-report.component";
import { ProviderOutreachByContactTypeComponent } from "./analytics-risk/provider-outreach-by-contact-type/provider-outreach-by-contact-type.component";
import { ProviderStatusDwComponent } from "./analytics-risk/provider-status-dw/provider-status-dw.component";
import { ProviderStatusComponent } from "./analytics-risk/provider-status/provider-status.component";
import { ProviderValidationComponent } from "./analytics-risk/provider-validation/provider-validation.component";
import { RetrievalReportByChaseComponent } from "./analytics-risk/retrieval-report-by-chase/retrieval-report-by-chase.component";
import { RetrievalReportComponent } from "./analytics-risk/retrieval-report/retrieval-report.component";
import { RetrievalSummaryDwComponent } from "./analytics-risk/retrieval-summary-dw/retrieval-summary-dw.component";
import { RetrievalSummaryComponent } from "./analytics-risk/retrieval-summary/retrieval-summary.component";
import { RiskClinicalSummaryComponent } from "./analytics-risk/risk-clinical-summary/risk-clinical-summary.component";
import { RiskNLPDashboardOverviewComponent } from "./analytics-risk/risk-nlp-dashboard-overview/risk-nlp-dashboard-overview.component";
import { RiskNLPICDDashboardOverviewComponent } from "./analytics-risk/risk-nlp-icd-dashboard-overview/risk-nlp-icd-dashboard-overview.component";
import { RiskNLPICDDrilldownComponent } from "./analytics-risk/risk-nlp-icd-drilldown/risk-nlp-icd-drilldown.component";
import { RiskProjectHealthComponent } from "./analytics-risk/risk-project-health/risk-project-health.component";
import { RiskScoreTrendComponent } from "./analytics-risk/risk-score-trend/risk-score-trend.component";
import { RoiDWComponent } from "./analytics-risk/roi-dw/roi-dw.component";
import { RoiComponent } from "./analytics-risk/roi/roi.component";
import { SftpReportDwComponent } from "./analytics-risk/sftp-report-dw/sftp-report-dw.component";
import { SftpReportComponent } from "./analytics-risk/sftp-report/sftp-report.component";
import { SlaMilestoneReportDwComponent } from "./analytics-risk/sla-milestone-report-dw/sla-milestone-report-dw.component";
import { StalledDocumentReportDwComponent } from "./analytics-risk/stalled-document-report-dw/stalled-document-report-dw.component";
import { StalledDocumentsReportComponent } from "./analytics-risk/stalled-documents-report/stalled-documents-report.component";
import { SuspectingComponent } from "./analytics-risk/suspecting/suspecting.component";
import { TopAidsWithOpenChasesComponent } from "./analytics-risk/top-aids-with-open-chases/top-aids-with-open-chases.component";
import { Top50AidsWithOpenChasesComponent } from "./analytics-risk/top50-aids-with-open-chases/top50-aids-with-open-chases.component";
import { TrainingFeedbackDwComponent } from "./analytics-risk/training-feedback-dw/training-feedback-dw.component";
import { TrainingFeedbackComponent } from "./analytics-risk/training-feedback/training-feedback.component";
import { UserProductivityDashboardComponent } from "./analytics-risk/user-productivity-dashboard/user-productivity-dashboard.component";
import { UserProductivityComponent } from "./analytics-risk/user-productivity/user-productivity.component";
import { ValidationReasonCodeComponent } from "./analytics-risk/validation-reason-code/validation-reason-code.component";
import { ValidationReasonsCodeReportDwComponent } from "./analytics-risk/validation-reasons-code-report-dw/validation-reasons-code-report-dw.component";

const defaultPaths = ["/analytics/hedis/reports", "/analytics/iva/reports", "/analytics/risk/reports", "/analytics/membermanagement/reports"];

const routes: Routes = [

  {
    path: "hedis/clinical_productivity_overview", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/clinical/mrr", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/clinical/or1", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/clinical/or2", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/chart_routing", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/clinical/summary", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/gap_status", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/gap_status", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/member_additional_details", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/overview", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/pends/summary", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports", component: AnalyticsReportLandingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/chase_numerator_level_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/clinical_accuracy_by_chase_with_error_detail_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/clinical_accuracy_by_chase_with_error_detail_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/clinical_pends_with_compliance_change", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/clinical_productivity_and_accuracy-Overall", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/clinical_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/coder_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/coder_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/dob_discrepancy", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/dw/member_measure_compliance_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/dw/eve_quality_match_rate_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/intake_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/intake_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/open_gaps_by_measure", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/open_gaps_by_member", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/pend_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/project_status_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/project_summary", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/quality_nlp_performance_by_gap", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/quality_nlp_performance_by_member", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/quality_nlp_performance_by_provider", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/quality_nlp_results_overview", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/sample_compliance_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/sample_compliance_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/top50_aids_with_open_chases", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/user_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/client_overread2_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/client_overread2_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/call_count_detail_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/call_count_detail_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/clinical_accuracy_by_measure", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/clinical_accuracy_by_measure", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/manually_created_chases_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/chase_move_back_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/retrieval_report_by_chase", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/member_centric_chase_count", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/member_centric_chart_count", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/retrieval/docintake", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/retrieval/docqa", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/retrieval/emr", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/retrieval/ft", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/retrieval/psr", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/retrieval/summary", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/retrieval/summary", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/ft_daily_summary_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/coder_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/bot_overread_overview", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/retrieval_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/clinical_qa_report_by_measure", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/member_measure_non_compliant_summary_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/training_feedback_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/training_feedback_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/member_measure_compliance_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/chases_in_delivery_with_images", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/provider_outreach_by_contact_type_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/sftp_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/sftp_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/hedis_chase_count_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/prospective_chase_count_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/record_count_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/load_count_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/provider_validation_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/chase_submeasure_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/program_retrieval_projections", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/gap_performance", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/nrc_list_by_measure", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/detail_clinical_productivity_and_accuracy_overall", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/member_centric_details_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/member_centric_overview", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/retrieval/intake_sla_dashboard", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/chase_move_back_history_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/chase_move_back_history_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "hedis/reports/customer_invoice_detail", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/coding_duration_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/coding_duration_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/stalled_documents_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/stalled_documents_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/provider_gateway_activity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/contacts_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/quality_aid_feedback_summary_and_detail_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/chase_move_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/chase_move_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/new_pages_added_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/eve_reviewed_vs_eve_found_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/clinical_productivity_and_accuracy-overall", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/clinical_productivity_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/chase_numerator_level_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/overview", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/clinical/summary", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/document_validation_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/user_productivity_dashboard", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/chase_counts_by_chase_statuses", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/chart_routing", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/pend_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/pends/summary", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/dob_discrepancy", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/Chase_Status_Underlying", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/Life_of_Chase", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/load_error", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/annotations", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/chase_submeasure_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/project_status_report", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/reports/dw/sla", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "quality/dw/reports/top_aids_with_open_chases", component: AnalyticsHedisComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/clinical_productivity_overview", component: ClinicalProductivityOverviewComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/clinical/summary", component: ClinicalSummaryComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/condition_category_qa_report", component: ConditionCategoryQaReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/condition_category_qa_report", component: ConditionCategorQaReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/gap_status", component: GapStatusComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/suspecting", component: SuspectingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/member_additional_details", component: MemberAdditionalDetailsComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/retrieval/summary", component: RetrievalSummaryComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/retrieval/summary", component: RetrievalSummaryDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/vrc", component: ValidationReasonCodeComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/overview", component: AnalyticsRiskOverviewComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/overview", component: AnalyticsRiskOverviewDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports", component: AnalyticsReportLandingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/chase_status_underlying", component: ChaseStatusUnderlyingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/clinical_accuracy_by_measure", component: ClinicalAccuracyByMeasureComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/clinical_productivity_and_accuracy-Overall", component: ClinicalProductivityAndAccuracyOverallComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/clinical_productivity_report", component: ClinicalProductivityComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/coder_productivity_report", component: CoderProductivityComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/coder_productivity_report", component: CoderProductivityDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/diagnosis_by_hcc", component: DiagnosisByHccComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/diagnosis_by_hcc", component: DiagnosisByHccDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/hcc_tracking", component: HccTrackingReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/risk_score_trend", component: RiskScoreTrendComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dob_discrepancy", component: DobDiscrepancyComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/ft_daily_summary_report", component: FtDailySummaryComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/hcc_summary_by_aid", component: HccSummaryByAidComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/hcc_summary_by_aid", component: HccSummaryByAidDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/hcc_summary_by_member", component: HccSummaryByMemberComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/hcc_summary_by_member", component: HccSummaryByMemberDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/hcc_summary_by_project", component: HccSummaryByProjectComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/hcc_summary_by_project", component: HccSummaryByProjectDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/hcc_summary_by_provider", component: HccSummaryByProviderComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/hcc_summary_by_provider", component: HccSummaryByProviderDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/hcc/summary", component: HccSummaryReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/intake_productivity_report", component: IntakeProductivityComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/intake_productivity_report", component: IntakeProductivityLegacyComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/life_of_a_chase", component: LifeOfAChaseComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/member_chase", component: MemberChaseComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/member_chase", component: MemberChaseDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/open_gaps_by_measure", component: OpenGapsByMeasureComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/open_gaps_by_member", component: OpenGapsByMemberComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/pend_report", component: PendReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/project_status_report", component: ProjectStatusReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/project_status_report", component: ProjectStatusReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/project_summary", component: ProjectSummaryComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/project_summary", component: ProjectSummaryDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/provider_status", component: ProviderStatusComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/provider_status", component: ProviderStatusDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/provider_validation_report", component: ProviderValidationComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/retrieval_report", component: RetrievalReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/risk_diagnosis_data", component: HccSummaryByClaimIdComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/top50_aids_with_open_chases", component: Top50AidsWithOpenChasesComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/pend_report", component: PendReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/clinical_productivity_and_accuracy-Overall", component: ClinicalProductivityAndAccuracyOverallComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/risk_project_health", component: RiskProjectHealthComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/condition_category_qa_report", component: ConditionCategoryQaReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/retrieval_report", component: RetrievalReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/training_feedback_report", component: TrainingFeedbackComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/training_feedback_report", component: TrainingFeedbackDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/auto_close_report", component: AutoCloseReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/client_overread2_report", component: ClientOverread2Component, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/client_overread2_report", component: ClientOverread2DWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/call_count_detail_report", component: CallCountDetailReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/call_count_detail_report", component: CallCountDetailReportLegacyComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/provider_outreach_by_contact_type_report", component: ProviderOutreachByContactTypeComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/user_productivity_report", component: UserProductivityComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/retrieval_report_by_chase", component: RetrievalReportByChaseComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/sftp_report", component: SftpReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/sftp_report", component: SftpReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/clinical_accuracy_by_chase_with_error_detail_report", component: ClinicalAccuracyByChaseWithErrorDetailComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/clinical_accuracy_by_chase_with_error_detail_report", component: ClinicalAccuracyByChaseWithErrorDetailLegacyComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/customer_invoice_detail", component: CustomerInvoiceDetailComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/risk_nlp_dashboard_overview", component: RiskNLPDashboardOverviewComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/risk_nlp_icd_dashboard_overview", component: RiskNLPICDDashboardOverviewComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/risk_nlp_icd_drilldown", component: RiskNLPICDDrilldownComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/manually_created_chases_report", component: ManaullyCreatedChasesReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/detail_clinical_productivity_and_accuracy_overall", component: DetailClinicalProductivityAndAccuracyOverallComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/clinical/clinical_production_inventory_and_qa_scores", component: ClinicalProductionInventoryAndQaScoresComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/chase_move_back_report", component: ChaseMoveBackReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/chase_move_back_history_report", component: ChaseMoveBackHistoryReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/chase_move_back_history_report", component: ChaseMoveBackHistoryReportLegacyComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/pends/summary", component: PendsSummaryComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/pends/summary", component: PendsSummaryDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/persistency_report", component: PersistencyReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/persistency_report", component: PersistencyDWReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/EVE_Risk_Confidence_Score_Report", component: ConfidenceScoreReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/roi", component: RoiComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/roi", component: RoiDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/historical_status_indicators_report", component: HistoricalStatusIndicatorsReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/historical_status_indicators_report", component: HistoricalStatusIndicatorsDWReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/clinical_productivity_and_accuracy-overall", component: ClinicalProductivityAccuracyOverallDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/clinical_productivity_report", component: ClinicalProductivityReportDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/coding_duration_report", component: CodingDurationReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/coding_duration_report", component: CodingDurationReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/stalled_documents_report", component: StalledDocumentReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/hcc_by_dos", component: HccByDosComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/clinical/summary", component: RiskClinicalSummaryComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/stalled_documents_report", component: StalledDocumentsReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/provider_gateway_activity_report", component: ProviderGatewayActivityComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/chase_move_report", component: ChaseMoveReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/chase_move_report", component: ChaseMoveReportDwComponent , data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/chart_routing", component: ChartRoutingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/deletes_tracking", component: DeletesTrackingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/deletes_tracking", component: DeletesTrackingDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/user_productivity_dashboard", component: UserProductivityDashboardComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/chase_counts_by_chase_statuses", component: ChaseCountsByStatusReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/detailed_audit_report", component: DetailedAuditReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/pend_report", component: PendReportDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/aid_tag_history_tracking", component: AidTagHistoryTrackingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/dob_discrepancy", component: DobDiscrepancyDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/Chase_Status_Underlying", component: ChaseStatusUnderlyingReportDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/Life_of_Chase", component: LifeOfAChaseDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/risk_diagnosis_data", component: DiagnosisDataMultipleModelsDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/annotations", component: AnnotationsReportDWComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/load_error", component: DataLoadErrorReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/sla", component: SlaMilestoneReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/vrc", component: ValidationReasonsCodeReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/reports/top_aids_with_open_chases", component: TopAidsWithOpenChasesComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/provider_outreach_by_contact_type_report", component: ProviderOutreachByContactTypeReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/dw/chart_routing", component: ChartRoutingDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "risk/reports/dw/edps_report", component: EdpsReportDwComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/top50_aids_with_open_chases", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/pend_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/project_status_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/project_status_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/clinical_productivity_and_accuracy-Overall", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/retrieval_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/coder_productivity_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/clinical_productivity_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/provider_validation_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/call_count_detail_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/call_count_detail_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/training_feedback_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/sftp_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/retrieval_report_by_chase", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/enr_status_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/rxc_status_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/iva_diagnosis_data", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/hcc_by_enrollee_ID", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/hcc_by_stratum", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/iva_pursuit_prioritization_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/iva_hst_prioritization_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/iva_overview", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/edge_hcc_performance_summary", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/clinical/summary", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/chart_routing", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/retrieval/summary", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/dw/retrieval/summary", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/pends/summary", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/dw/pends/summary", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports", component: AnalyticsReportLandingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/clinical_accuracy_by_chase_with_error_detail_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/clinical_productivity_and_accuracy-Overall", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/coder_productivity_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dob_discrepancy", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/ft_daily_summary_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/intake_productivity_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/pend_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/project_status_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/project_summary", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/retrieval_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/top50_aids_with_open_chases", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/client_overread2_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/iva_client_overread_2_history_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/auto_close_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/user_productivity_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/provider_outreach_by_contact_type_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/retrieval/program_retrieval_projections", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/clinical/clinical_production_inventory_and_qa_scores", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/detail_clinical_productivity_and_accuracy_overall", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/risk_nlp_icd_dashboard_overview", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/risk_nlp_icd_dashboard_overview", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/chase_move_back_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/chase_move_back_history_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/chase_move_back_history_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/customer_invoice_detail", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/chase_move_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/chase_move_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/chase_counts_by_chase_statuses", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/pend_report", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/dob_discrepancy", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/Chase_Status_Underlying", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/Life_of_Chase", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/reports/dw/sla", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "iva/dw/reports/top_aids_with_open_chases", component: AnalyticsIvaComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "membermanagement/reports", component: AnalyticsReportLandingComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "membermanagement/dw/reports/member_oversight_dashboard", component: MemberOversightDashboardComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },
  {
    path: "membermanagement/dw/reports/membership_history_report", component: MembershipHistoryReportComponent, data: { defaultPath: defaultPaths },
    canActivate: [AuthGuard],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule { }
