import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { finalize } from "rxjs/operators";
import { SelectableInput } from "../../../../../../dynamic-forms/inputs/selectable-input.model";
import { ArrayHelper } from "../../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../../utilities/contracts/string-helper";
import { DynamicEntityAttribute } from "../../../../../api/member-validation/dynamic-entity-attribute.model";
import { CHART_PAGE_NUMBER, CONTRA_DATE, CONTRA_TYPE, DATE_OF_DEATH, EXCLUSION_DATE, EXCLUSION_TYPE } from "../attributes";
import { ChartService } from "../chart.service";
import { NonCompliantInfo } from "./non-compliant-info.model";

@Component({
  selector: "member-non-compliant-info",
  templateUrl: "./non-compliant-info.component.html",
  styleUrls: [
    "../../../../../../dynamic-forms/form-groups/standard-group/standard-group.component.scss",
    "../../../../../../dynamic-forms/form-groups/save-group/save-group.component.scss",
    "../../../../../../dynamic-forms/inputs/textbox/textbox.component.scss",
    "../../../../../../dynamic-forms/inputs/dropdown/dropdown.component.scss",
    "./non-compliant-info.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NonCompliantInfoComponent implements OnChanges, OnInit {
  @Input() type: "Exclusion" | "Contra";
  @Input() configuration: NonCompliantInfo;
  @Input() enabled = true;
  @Input() isOverread: boolean;
  @Input() exclusionIndex: number;
  @Output() onError = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter<number>();
  @Output() onSave = new EventEmitter<{ index: number; exclusion: NonCompliantInfo}>();

  errorMessage = "";

  private valueChanges = {
    reasonInfo: false,
    dateInfo: false,
    dateOfDeathInfo: false,
    pageNumberInfo: false,
  };

  constructor(
    private readonly chartService: ChartService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.configuration && this.isOverread) {
      this.configuration.getAttributes().forEach(attribute => this.checkChanges(attribute));
    }
  }

  ngOnInit() {
    this.checkDateOfDeathAttributes();
  }

  private checkChanges(entity: DynamicEntityAttribute) {
    switch (entity?.attributeId) {
      case CONTRA_TYPE.attributeId:
      case EXCLUSION_TYPE.attributeId:
        this.valueChanges.reasonInfo = entity.isChanged;
        break;
      case EXCLUSION_DATE.attributeId:
      case CONTRA_DATE.attributeId:
        this.valueChanges.dateInfo = entity.isChanged;
        break;
      case CHART_PAGE_NUMBER.attributeId:
        this.valueChanges.pageNumberInfo = entity.isChanged;
        break;
      case DATE_OF_DEATH.attributeId:
        this.valueChanges.dateOfDeathInfo = entity.isChanged;
        break;
      default:
        break;
    }
    this.changeDetector.markForCheck();
  }

  get dropdownClass(): string {
    if (this.exclusionChange) {
      return "control__input control__input--dropdown control__input--overread";
    }
    return "control__input control__input--dropdown";
  }

  get exclusionChange(): boolean {
    return this.valueChanges.reasonInfo;
  }

  get dateChange(): boolean {
    return this.valueChanges.dateInfo;
  }

  get dateOfDeathChange(): boolean {
    return this.valueChanges.dateOfDeathInfo;
  }

  get pageNumberChange(): boolean {
    return this.valueChanges.pageNumberInfo;
  }

  get hasErrorMessage(): boolean {
    return StringHelper.isAvailable(this.errorMessage);
  }

  get showDateOfDeath(): boolean {
    return this.reasonText.includes("Member expired during") && this.type !== "Contra";
  }

  get getDateLabel(): string {
    return this.showDateOfDeath ? "Date Documented" : "Date";
  }

  get classes(): any {
    return {
      "control--error": this.hasErrorMessage,
    };
  }

  get reason(): string {
    return this.configuration.reason;
  }
  set reason(value: string) {
    this.configuration.reason = value;
    this.configuration.dateInfo.value = null;
    this.configuration.pageNumberInfo.value = null;
    // Reset dateOfDeath when the reason is modifed from 'Member expired during' option.
    if (!this.showDateOfDeath) {
      this.configuration.dateOfDeath = "";
    } else {
      this.checkDateOfDeathAttributes();
    }

    this.save();
  }

  get reasonText(): string {
    return this.selectedReasonOption ? this.selectedReasonOption.text : "";
  }

  get selectedReasonOption(): SelectableInput {
    return StringHelper.isAvailable(this.reason) || NumberHelper.isAvailable(this.reason as any)
      ? this.reasonOptions.find(option => option.value === this.reason)
      : null;
  }

  get reasonOptions(): SelectableInput[] {
    return this.configuration?.reasonOptions;
  }

  get hasReasonOptions(): boolean {
    return ArrayHelper.isAvailable(this.reasonOptions);
  }

  get date(): string {
    return this.configuration.date;
  }
  set date(value: string) {
    this.configuration.date = value;
    this.save();
  }

  get dateOfDeath(): string {
    return this.configuration.dateOfDeath;
  }
  set dateOfDeath(value: string) {
    this.checkDateOfDeathAttributes();

    this.configuration.dateOfDeath = value;
    this.save();
  }

  get pageNumber(): number {
    return this.configuration.pageNumber;
  }
  set pageNumber(value: number) {
    this.configuration.pageNumber = value;
    this.save();
  }

  checkDateOfDeathAttributes(): void {
    /** Update dateOfDeathInfo attributes when they are not available.
    We have to update this info manually as it's initially not available for dateOfDeath with empty values.*/
    if (!StringHelper.isAvailable(this.configuration.dateOfDeathInfo?.code)) {
      this.updateDateOfDeathAttributes();
    }
  }

  updateDateOfDeathAttributes(): void {
    const dateOfDeathInfo = this.configuration.dateOfDeathInfo;
    const dateInfo = this.configuration.dateInfo;

    dateOfDeathInfo.code = DATE_OF_DEATH.attributeCode;
    dateOfDeathInfo.attributeCode = DATE_OF_DEATH.attributeCode;
    dateOfDeathInfo.id = DATE_OF_DEATH.attributeId;
    dateOfDeathInfo.attributeId = DATE_OF_DEATH.attributeId;
    dateOfDeathInfo.chaseId = dateInfo.chaseId;
    dateOfDeathInfo.entityId = dateInfo.entityId;
    dateOfDeathInfo.entityTypeId = dateInfo.entityTypeId;

    this.configuration.dateOfDeathInfo = dateOfDeathInfo;
  }

  private isValid(): boolean {
    const isValidReason = StringHelper.isAvailable(this.reason);
    const isValidDate = StringHelper.isAvailable(this.date);
    const isValidDateOfDeath = StringHelper.isAvailable(this.dateOfDeath);
    const isValidPageNumber = !!this.pageNumber;
    return (isValidReason && isValidDate) ||
      (isValidReason && isValidPageNumber) ||
      (isValidDate && isValidPageNumber) ||
      (isValidReason && isValidDateOfDeath);
  }

  private save(): void {
    this.onError.emit("saving");

    if (this.isValid()) {
      const attributes = this.configuration.getAttributes();
      this.chartService.save(attributes)
        .pipe(finalize(() => {
          this.chartService.onChangeEmit();
          this.changeDetector.markForCheck();
        }))
        .subscribe(
          newAttributes => {
            const { entityId } = newAttributes[1];
            newAttributes.forEach(newAttribute => {
              this.checkChanges(newAttribute);
              let reasonInfo = this.configuration.reasonInfo;
              let dateInfo = this.configuration.dateInfo;
              let dateOfDeathInfo = this.configuration.dateOfDeathInfo;
              let pageNumberInfo = this.configuration.pageNumberInfo;

              switch (newAttribute.id) {
                case reasonInfo.id:
                  const options = reasonInfo.options;
                  reasonInfo = newAttribute;
                  reasonInfo.options = options;
                  break;
                case dateInfo.id:
                  dateInfo = newAttribute;
                  break;
                case dateOfDeathInfo.id:
                  dateOfDeathInfo = newAttribute;
                  break;
                case pageNumberInfo.id:
                  pageNumberInfo = newAttribute;
                  break;
                default:
                  break;
              }

              this.configuration = new NonCompliantInfo({ reasonInfo, dateInfo, dateOfDeathInfo, pageNumberInfo });
            });
            this.errorMessage = "";
            this.onError.emit(this.errorMessage);
            this.configuration.reasonInfo.entityId = entityId;
            this.configuration.dateInfo.entityId = entityId;
            this.configuration.dateOfDeathInfo.entityId = entityId;
            this.configuration.pageNumberInfo.entityId = entityId;
            this.onSave.emit({ index: this.exclusionIndex, exclusion: this.configuration });
          },
          error => {
            this.errorMessage = error.error;
            this.onError.emit(this.errorMessage);
          }
        );
    } else {
      this.errorMessage = "";
      this.onError.emit(this.errorMessage);
    }
  }

  remove(): void {
    if (this.enabled) {
      const attributes = this.configuration.getAttributes();

      // Delete only in UI if values are empty, as they don't exist in the DB.
      // Allow DB deletion with empty values only for the DateOfDeath attribute.
      if (!attributes.every(att => att?.value || att?.attributeCode === DATE_OF_DEATH.attributeCode)) {
        this.onDelete.emit(this.exclusionIndex);
        return;
      }
      this.chartService.delete(attributes)
        .pipe(finalize(() => {
          this.chartService.onChangeEmit();
          this.changeDetector.markForCheck();
        }))
        .subscribe(() => {
          this.configuration.reasonInfo.entityId = null;
          this.configuration.dateInfo.entityId = null;
          this.configuration.dateOfDeathInfo.entityId = null;
          this.configuration.pageNumberInfo.entityId = null;

          this.configuration.reason = null;
          this.configuration.date = null;
          this.configuration.dateOfDeath = null;
          this.configuration.pageNumber = null;

          this.onDelete.emit(this.exclusionIndex);

          this.changeDetector.markForCheck();
        });
    }
  }

  formatDate(event: string): string {
    return DateHelper.format(event);
  }

  trackByIndex(index, item) {
    return index;
  }
}
